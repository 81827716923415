import React from 'react';
import Section from '../components/section';
import SectionHero from '../components/sectionHero';
import Card from '../components/card';
import ProcessedHTML from '../components/processedHtml';
import EarlyAccessSignup from "../components/EarlyAccessSignup";
import Link from "../components/Link";
import LinkButton from "../components/linkButton";

const Home = ({ resource }) => {
  const { title, hero, sections, links } = resource;
  const edit = links.get('edit-form');
    const icons = [
     <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><defs><linearGradient id="a" x1="0%" x2="57.764%" y1="-5.307%" y2="108.797%"><stop offset="0%" stopColor="#57048A"/><stop offset="100%" stopColor="#4528DC"/></linearGradient></defs><path fill="url(#a)" fillRule="nonzero" d="M48,-2 C55.7319865,-2 62,4.2680135 62,12 C62,19.7319865 55.7319865,26 48,26 C44.6628824,26 41.5984702,24.8324089 39.1929954,22.8834584 L21.0889502,34.4053801 C21.6759882,35.8205861 22,37.3724546 22,39 C22,40.0017148 21.8772609,40.9747625 21.6460015,41.9049241 L35.9954236,47.6453075 C37.6142746,44.304038 41.0380689,42 45,42 C50.5228475,42 55,46.4771525 55,52 C55,57.5228475 50.5228475,62 45,62 C39.4771525,62 35,57.5228475 35,52 C35,51.8515074 35.0032366,51.7037707 35.009643,51.5568567 L20.0426913,45.5709309 C17.899034,48.8405413 14.2017013,51 10,51 C3.372583,51 -2,45.627417 -2,39 C-2,32.372583 3.372583,27 10,27 C13.5627829,27 16.7629251,28.5526488 18.9606993,31.018219 L36.4381261,19.8968707 C34.8998081,17.6489408 34,14.9295511 34,12 C34,4.2680135 40.2680135,-2 48,-2 Z M45,46 C42.7229329,46 40.7421302,47.2684583 39.7256501,49.1373164 L39.7427814,49.1430466 L39.1721632,50.5671307 C39.0596592,51.0262769 39,51.5061714 39,52 C39,55.3137085 41.6862915,58 45,58 C48.3137085,58 51,55.3137085 51,52 C51,48.6862915 48.3137085,46 45,46 Z M10,31 C5.581722,31 2,34.581722 2,39 C2,43.418278 5.581722,47 10,47 C14.418278,47 18,43.418278 18,39 C18,34.581722 14.418278,31 10,31 Z M48,2 C42.4771525,2 38,6.4771525 38,12 C38,17.5228475 42.4771525,22 48,22 C53.5228475,22 58,17.5228475 58,12 C58,6.4771525 53.5228475,2 48,2 Z" transform="translate(2 2)"/></svg>,
     <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><defs><linearGradient id="a" x1="0%" x2="57.764%" y1="8.537%" y2="94.08%"><stop offset="0%" stopColor="#57048A"/><stop offset="100%" stopColor="#4528DC"/></linearGradient></defs><path fill="url(#a)" fillRule="nonzero" d="M64,4 L64,8 L22,8 L22,4 L64,4 Z M56,14 L56,18 L22,18 L22,14 L56,14 Z M64,24 L64,28 L22,28 L22,24 L64,24 Z M58,34 L58,38 L22,38 L22,34 L58,34 Z M61,44 L61,48 L57,48 L57,44 L61,44 Z M55,44 L51,44 L51,48 L55,48 L55,44 Z M49,44 L45,44 L45,48 L49,48 L49,44 Z M43,44 L39,44 L39,48 L43,48 L43,44 Z M37,44 L33,44 L33,48 L37,48 L37,44 Z M31,44 L27,44 L27,48 L31,48 L31,44 Z M21,48 L21,44 L25,44 L25,48 L21,48 Z M21,50 L21,53 L25,53 L25,50 L21,50 Z M21,54 L25,54 L25,58 L21,58 L21,54 Z M27,58 L31,58 L31,54 L27,54 L27,58 Z M33,58 L37,58 L37,54 L33,54 L33,58 Z M39,58 L43,58 L43,54 L39,54 L39,58 Z M45,58 L49,58 L49,54 L45,54 L45,58 Z M51,58 L55,58 L55,54 L51,54 L51,58 Z M57,54 L61,54 L61,58 L57,58 L57,54 Z M61,53 L61,50 L57,50 L57,53 L61,53 Z M11.4142136,42.5857864 L18.4142136,49.5857864 L18.4142136,52.4142136 L11.4142136,59.4142136 L8.58578644,56.5857864 L12.171,52.9997864 L0,53 L0,49 L12.171,48.9997864 L8.58578644,45.4142136 L11.4142136,42.5857864 Z"/></svg>,
     <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><defs><linearGradient id="a" x1="0%" x2="57.764%" y1="-5.307%" y2="108.797%"><stop offset="0%" stopColor="#57048A"/><stop offset="100%" stopColor="#4528DC"/></linearGradient></defs><path fill="url(#a)" fillRule="nonzero" d="M32.0004838,0 C49.6747704,0 64.00103,14.3263475 64.0000005,31.9998836 C64.00103,49.6748146 49.6735574,64.002057 31.999636,64 C14.3247958,64.0010285 -0.00102658204,49.6751183 4.97803853e-07,32.0013133 C-0.00308351909,14.3267602 14.3239069,0 32.0004838,0 Z M32.0004838,4 C16.5331479,4 3.99730209,16.5356443 4.00000059,32.0010806 C3.99910192,47.4660357 16.5338893,60.0008999 31.9997523,60 C47.4645735,60.0017999 60.0009014,47.4656733 60.0000006,31.9998836 C60.0009014,16.5353912 47.4655871,4 32.0004838,4 Z M42.3015828,28.5185146 L35.8796722,34.0223765 C35.9582629,34.3352476 36,34.6627504 36,35.0000014 C36,37.2091404 34.209139,39.0000014 32,39.0000014 C29.790861,39.0000014 28,37.2091404 28,35.0000014 C28,32.7908624 29.790861,31.0000014 32,31.0000014 C32.3763317,31.0000014 32.7405249,31.051972 33.0857943,31.1491276 L39.6984173,25.4814882 L42.3015828,28.5185146 Z M48.2631,15.7373594 C52.5248855,19.9989997 54.8804562,25.6509599 54.9955711,31.5460698 L55.0000015,32 L51.0000015,32 C51.0000015,26.9921364 49.047932,22.1789225 45.4345565,18.5656702 C38.0151372,11.1447766 25.9848657,11.1447766 18.5652818,18.5658348 C15.0583418,22.0726552 13.1162661,26.7100444 13.00506,31.5586604 L13.0000015,32 L9.00000146,32 C9.00000146,25.9417181 11.3658409,20.1082725 15.7367383,15.737524 C24.718319,6.75415865 39.2816839,6.75415865 48.2631,15.7373594 Z"/></svg>,
    ];
    return (
        <>
            <SectionHero label="Reach Escape Velocity">
              <>
                {edit
                  ? <Link title="Edit" href={edit.href} classes={'edit-link'} />
                  : null
                }
                <div className="max-w-prose">
                    <h1 className="mt-4">{title}</h1>
                    <ProcessedHTML html={hero} />
                    <div className="my-8">
                      <LinkButton link={{ href: "https://form.typeform.com/to/Mks8qhha",  title: "Get a launch invite" }} />
                    </div>
                </div>
              </>
            </SectionHero>
            <Section label="Features">
                <h3 className="mt-0">You make the front end, we make it work.</h3>
                <div className="my-8 mb-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    {sections?.data.map((section, i) => (
                      <Card
                        key={`section-${i}`}
                        img={icons[i]}
                        title={section.heading}
                      >
                          <ProcessedHTML html={section.content} />
                      </Card>
                    ))}
                </div>
            </Section>
            <EarlyAccessSignup />
        </>
    )

}

export default Home;
