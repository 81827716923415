import React from "react";
import SectionCenter from "./sectionCenter";
import LinkButton from "./linkButton";

const EarlyAccessSignup = () => (

  <SectionCenter label="Get Started" modifier="bg-transparent">
    <h3 className="mt-0">Get an invite</h3>
    <p>Sign up to receive a launch invite</p>
    <LinkButton link={{
      href: 'https://form.typeform.com/to/Mks8qhha',
      title: 'Sign up',
    }} />
  </SectionCenter>
);

export default EarlyAccessSignup;
